import { mapGetters } from 'vuex';
import { StatusConstants } from '@/constants/status';
import status from '@/mixins/plugin/status';

export default {
  data() {
    return {
      judgeTypes: StatusConstants.judge,
      pauseLabel: StatusConstants.shop.pause.label,
      preparationLabel: StatusConstants.shop.preparation.label,
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  mixins: [status],
  methods: {
    checkApprovedItem(item) {
      return item?.status === this.judgeTypes.approved.value && (!item.openFlag || !item.validFlag);
    },
    getStatusApprovedItem(item) {
      const statusName = this.getShopStatusName(item.status);
      return this.checkApprovedItem(item) ?
        [
          {
            content: this.preparationLabel,
            class: !item.openFlag ? 'status-green' : '',
          },
          {
            content: this.pauseLabel,
            class: !item.validFlag ? 'status-green' : '',
          }
        ] :
        [
          {
            content: statusName,
            class: this.statusClass(statusName),
            value: item.status,
          },
        ];
    }
  }
};
